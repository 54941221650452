import React, { useState } from 'react';
import { Alert, Container, Spinner } from 'react-bootstrap';
import { subscribe } from '../../../services/subscriptionService';
import { useForm, SubmitHandler } from "react-hook-form";
import './Subscription.scss';
import ScheduleSelector from '../../ScheduleSelector/ScheduleSelector';
import { DateTime } from 'luxon';
import { useSchedule } from '../../../hooks/scheduleHooks';
import { Schedule } from '../../../models/Models';
import { getVolumeNumberFromName } from '../../../services/scripturesService';
import { getUserId } from '../../../services/userService';
import { getTotalDays } from '../../../services/scheduleService';

type Inputs = {
  name: string,
  email: string,
  marketing: boolean
};


function Subscription() {
  const [loading, setLoading] = useState(false);
  const [storedSchedule] = useSchedule(DateTime.local());
  const { register, handleSubmit } = useForm<Inputs>();
  let [subSchedule, setSchedule] = useState<Schedule>(storedSchedule.schedule);
  let [errorMessage, setErrorMessage] = useState<string>();
  let [subscribed, setSubscribed] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    try {
      const req = {
        deliveryMethod: 'email',
        destination: data.email,
        frequency: 'daily',
        marketing: data.marketing,
        settings: {
          schedule: {
            volume: getVolumeNumberFromName(subSchedule.volume),
            totalDays: getTotalDays(subSchedule),
            startDate: {
              month: subSchedule.startDate.month,
              day: subSchedule.startDate.day,
              year: subSchedule.startDate.year
            }
          }
        },
        userName: data.name,
        userId: getUserId()
      };
      await subscribe(req);
      setSubscribed(true);
    } catch(error: any) {
      let msg = "An error occured while trying to subscribe";
      if (error.response) {
        console.error('Error while subscribing. Response is', error.response);
        msg += `(${error.response.status})`
        if (error.response.data.message) {
          msg += `. ${error.response.data.message}`;
        } else {
          if (error.response.status < 500) {
            msg += `. Check your information and try again.`
          } else {
            msg += `. Please try again later.`;
          }
        }
      } else {
        msg += ". Please try again later.";
      }
      setErrorMessage(msg);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <h2>Enjoy Scriptures Every Day?</h2>

      <div id="subscribe" className="subscription">
        {!subscribed && <div>
          <p>Let the scriptures come to you. Sign up to receive scriptures every day straight to your inbox.</p>
          <p><small>We'll send you a daily email with the current day's reading.</small></p>
          <ScheduleSelector schedule={subSchedule} onScheduleChanged={setSchedule} />
          <form className="subscription-form" name="subscriptionForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="subscriptionName">Name: </label>
              <input id="subscriptionName" type="text" {...register("name", { required: true })} required={true} />
            </div>

            <div className="form-group">
              <label htmlFor="subscriptionEmail">Email: </label>
              <input id="subscriptionEmail" type="email" {...register("email", { required: true })} required={true} />
            </div>

            <div className="form-group">
              <label className="marketing">
                <input type="checkbox" {...register('marketing')} />
                <span className="label-body">Also send me occasional news from Scriptures Every Day</span>
              </label>
            </div>

            {errorMessage && <Alert variant='danger'>
              <Alert.Heading>Error</Alert.Heading>
              <p>{errorMessage}</p>
            </Alert>}

            <button type="submit" className="button-primary" disabled={loading}>
              {loading && <>Subscribing...&nbsp;&nbsp;<Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /></>}
              {!loading && <>Subscribe</>}
            </button>
          </form>
        </div>}
        {subscribed && <Alert variant='success'>
          <Alert.Heading>Subscribed</Alert.Heading>
          <p>One last step: you'll have to check your email to confirm your email address. After that, you'll be good to go!</p>
        </Alert>}
      </div>

    </Container>
  );
}

export default Subscription;
