import { DateTime } from 'luxon';
import { DisplayScriptures, Schedule, VolumeName } from '../models/Models';
import { getUserId } from "./userService";
import axios from "axios";
import { getTotalDays } from './scheduleService';

const invalid = "Invalid Volume";
function last<T>(a: Array<T>): T { return a[a.length - 1]; }

export const getVolumeDisplayName = (volume: VolumeName) => {
    switch (volume) {
        case 'old-testament':
            return "The Bible - Old Testament";
        case 'new-testament':
            return "The Bible - New Testament";
        case 'book-of-mormon':
            return "The Book of Mormon";
        case 'doctrine-and-covenants':
            return "The Doctrine and Covenants";
        case 'pearl-of-great-price':
            return "The Pearl of Great Price";
        default:
            return invalid;
    }
}

export const getVolumeNumberFromName = (volume: VolumeName) => {
    switch (volume) {
        case "old-testament":
            return 1;
        case "new-testament":
            return 2;
        case "book-of-mormon":
            return 3;
        case "doctrine-and-covenants":
            return 4;
        case "pearl-of-great-price":
            return 5;
        default:
            return -1;
    }
}

export const getVolumeDisplayNameFromNumber = (volumeNumber: number) => {
    if (!isFinite(volumeNumber) || volumeNumber < 1 || volumeNumber > 5) {
        return invalid;
    }
    switch (volumeNumber) {
        case 1:
            return "The Bible - Old Testament";
        case 2:
            return "The Bible - New Testament";
        case 3:
            return "The Book of Mormon";
        case 4:
            return "The Doctrine and Covenants";
        case 5:
            return "The Pearl of Great Price";
        default:
            return invalid;
    }
}

export const getScriptures = (currentDate: DateTime, schedule: Schedule) => {
    var params = {
        startYear: schedule.startDate.year,
        startMonth: schedule.startDate.month,
        startDay: schedule.startDate.day,
        totalDays: getTotalDays(schedule),
        volume: getVolumeNumberFromName(schedule.volume),
        currentYear: currentDate.year,
        currentMonth: currentDate.month,
        currentDay: currentDate.day,
        userId: getUserId()
    };
    return axios.get(process.env.REACT_APP_API_SCRIPTURES + '/scriptures', {
        params
    });
};

export const getStartVerseId = (scriptures: DisplayScriptures) => {
    return scriptures[0].books[0].chapters[0].verses[0].id;
};

export const getEndVerseId = (scriptures: DisplayScriptures) => {
    return last(last(last(last(scriptures).books).chapters).verses).id;
};