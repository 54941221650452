import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { unsubscribe } from '../../../services/subscriptionService';
import './Unsubscribe.scss';

function Unsubscribe() {
  const [loading, setLoading] = useState(true);
  const { subscriptionId } = useParams<{ subscriptionId?: string }>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const action = async () => {
      if (subscriptionId) {
        try {
          await unsubscribe(subscriptionId);
        } catch (error: any) {
          let msg = "An error occured while trying to unsubscribe you";
          if (error.response) {
            console.error('Error while unsubscribing. Response is', error.response);
            msg += `(${error.response.status})`
            if (error.response.data.message) {
              msg += `. ${error.response.data.message}`;
            } else {
              msg += `. Please try again later.`;
            }
          } else {
            msg += ". Please try again later.";
          }
          setErrorMessage(msg);
        } finally {
          setLoading(false);
        }
      }
    }

    action();
  }, [subscriptionId])

  return (
    <Container>
      {loading && <div>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />&nbsp;&nbsp;Please wait while we unsubscribe you...
      </div>}
      {!loading && errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      {!loading && !subscriptionId && <Alert variant='danger'>Hmm, looks like we can't tell who to unsubscribe. Please try clicking on the unsubscribe link again.</Alert>}
      {!loading && !errorMessage && <Alert variant='success'>
        <p>You've been successfully unsubscribed.</p>
      </Alert>}
    </Container>
  );
}

export default Unsubscribe;
