import React, { FC, useState } from 'react';
import './SettingsBox.scss';
import ScheduleSelector from "../ScheduleSelector/ScheduleSelector";
import { Schedule, Settings } from '../../models/Models';

interface SettingsBoxProps {
  onCloseSettingsBox: () => void;
  onSettingsChanged: (settings: Settings) => void;
  initialSchedule: Schedule;
}

const SettingsBox: FC<SettingsBoxProps> = ({onCloseSettingsBox, onSettingsChanged, initialSchedule}) => {
  const [schedule, setSchedule] = useState<Schedule>(initialSchedule);

  const closeSettingsBox = () => {
    onCloseSettingsBox();
  }

  const saveSettings = () => {
    onSettingsChanged({
      schedule
    })
  }

  return (
    <div className="SettingsBox" data-testid="SettingsBox">
      <h2>Settings</h2>
      <h3>Set up your schedule</h3>
      <ScheduleSelector schedule={schedule} onScheduleChanged={setSchedule} />
      <button className="full-width button button-primary" onClick={saveSettings}>Save Settings</button>
      <button className="full-width button button-secondary" onClick={closeSettingsBox}>Close</button>

    </div>
  );
}

export default SettingsBox;
