import React, { useState } from 'react';
import './ScriptureReader.scss';
import SettingsBox from "../SettingsBox/SettingsBox";
import Scriptures from '../Scriptures/Scriptures';
import LoadedCustomScheduleAlert from "../LoadedCustomScheduleAlert/LoadedCustomScheduleAlert";
import { useHistory, useParams } from 'react-router-dom';
import { getURL } from '../../services/urlService';
import { DateTime } from 'luxon';
import { getDefaultSchedule } from '../../services/scheduleService';
import { Schedule, Settings } from '../../models/Models';
import { useSchedule } from "../../hooks/scheduleHooks";
import Box from "../Box/Box";

interface IScriptureReaderProps {
  onHideHelp: () => void;
  showScheduleSelector: boolean;
  onHideScheduleSelector: () => void;
  onShowScheduleSelector: () => void;
}

const ScriptureReader: React.FC<IScriptureReaderProps> = ({ onHideHelp, showScheduleSelector, onHideScheduleSelector, onShowScheduleSelector }) => {
  const [today] = useState(DateTime.local());
  const params = useParams<{ currentDate?: string }>();
  const [loadedSchedule, setAndSaveSchedule] = useSchedule(today);
  const loadedStoredSchedule = loadedSchedule.source === 'local';
  const schedule = loadedSchedule.schedule;
  const userRequestedDate = params.currentDate ? DateTime.fromISO(params.currentDate) : today;

  const history = useHistory();

  const updateUrlToNewSchedule = (schedule: Schedule) => {
    const url = getURL(schedule, userRequestedDate, today);
    history.push(url);
  }

  const onSettingsChanged = (settings: Settings) => {
    onHideHelp();
    onHideScheduleSelector();
    setAndSaveSchedule(settings.schedule);
    updateUrlToNewSchedule(settings.schedule);
  }

  const resetStoredSchedule = () => {
    onSettingsChanged({schedule: getDefaultSchedule(today)});
  }

  return (
    <div className="ScriptureReader" data-testid="ScriptureReader">
      {showScheduleSelector && <Box><SettingsBox initialSchedule={schedule} onCloseSettingsBox={() => onHideScheduleSelector()} onSettingsChanged={onSettingsChanged} /></Box>}
      {loadedStoredSchedule && <LoadedCustomScheduleAlert onResetSchedule={resetStoredSchedule} />}
      <Scriptures schedule={schedule} today={today} userRequestedDate={userRequestedDate} onShowSchedule={onShowScheduleSelector} />
    </div>
  );
}

export default ScriptureReader;
