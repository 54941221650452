import { DateTime } from 'luxon';
import { useState } from 'react';
import { Alert, Container, Spinner } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSchedule } from '../../../hooks/scheduleHooks';
import { Schedule } from '../../../models/Models';
import { getTotalDays } from '../../../services/scheduleService';
import { getVolumeNumberFromName } from '../../../services/scripturesService';
import { update } from '../../../services/subscriptionService';
import ScheduleSelector from '../../ScheduleSelector/ScheduleSelector';
import './UpdateSubscription.scss';

type Inputs = {
  name: string,
  email: string,
  marketing: boolean
};

function UpdateSubscription() {
  const { subscriptionId } = useParams<{ subscriptionId?: string }>();
  const [loading, setLoading] = useState(false);
  const [storedSchedule] = useSchedule(DateTime.local());
  const { handleSubmit } = useForm<Inputs>();
  let [subSchedule, setSchedule] = useState<Schedule>(storedSchedule.schedule);
  let [errorMessage, setErrorMessage] = useState<string>();
  let [subscribed, setSubscribed] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    try {
      const settings = {
        schedule: {
          volume: getVolumeNumberFromName(subSchedule.volume),
          totalDays: getTotalDays(subSchedule),
          startDate: {
            month: subSchedule.startDate.month,
            day: subSchedule.startDate.day,
            year: subSchedule.startDate.year
          }
        }
      };
      await update(subscriptionId!, settings);
      setSubscribed(true);
    } catch(error: any) {
      let msg = "An error occured while trying to update your subscription";
      if (error.response) {
        console.error('Error while updating subscription. Response is', error.response);
        msg += `(${error.response.status})`
        if (error.response.data.message) {
          msg += `. ${error.response.data.message}`;
        } else {
          if (error.response.status < 500) {
            msg += `. Check your information and try again.`
          } else {
            msg += `. Please try again later.`;
          }
        }
      } else {
        msg += ". Please try again later.";
      }
      setErrorMessage(msg);
    } finally {
      setLoading(false);
    }
  }
  
  return (
    
    <Container>
      <h2>Update your Scriptures Every Day subscription</h2>

      {!loading && !subscriptionId && <Alert variant='danger'>Hmm, looks like we can't tell who to unsubscribe. Please try clicking on the unsubscribe link again.</Alert>}

      {subscriptionId && <div id="update-subscription" className="update-subscription">
        {!subscribed && <div>
          <p>Update your existing subscription.</p>
          <p><small>We'll update your daily email to match your new schedule.</small></p>
          <ScheduleSelector schedule={subSchedule} onScheduleChanged={setSchedule} />
          <form className="subscription-form" name="subscriptionForm" onSubmit={handleSubmit(onSubmit)}>

            {errorMessage && <Alert variant='danger'>
              <Alert.Heading>Error</Alert.Heading>
              <p>{errorMessage}</p>
            </Alert>}

            <button type="submit" className="button-primary" disabled={loading}>
              {loading && <>Updating...&nbsp;&nbsp;<Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /></>}
              {!loading && <>Update Subscription</>}
            </button>
          </form>
        </div>}
        {subscribed && <Alert variant='success'>
          <Alert.Heading>Updated</Alert.Heading>
          <p>Great! We've updated your subscription. You should start seeing your new scriptures tomorrow.</p>
        </Alert>}
      </div>}

    </Container>
  );
}

export default UpdateSubscription;
