import axios from "axios";

export const subscribe = (config: {
    userId: string,
    userName: string,
    deliveryMethod: string, // Or deliveryMethod
    frequency: string,
    marketing: boolean,
    destination: string,
    settings: {
        schedule: {
            volume: number,
            totalDays: number,
            startDate: {
                month: number,
                day: number,
                year: number
            }
        }
    },
}) => {
    return axios({
        url: process.env.REACT_APP_API_SUBSCRIPTIONS + '/subscriptions',
        method: 'POST',
        data: config
    });
}

export const unsubscribe = (subscriptionId: string) => {
    return axios({
        url: process.env.REACT_APP_API_SUBSCRIPTIONS + '/subscriptions',
        method: 'DELETE',
        data: {
            subscriptionId
        }
    });
}

export const verify = (verificationToken: string) => {
    return axios({
        url: process.env.REACT_APP_API_SUBSCRIPTIONS + '/subscriptions',
        method: 'PUT',
        data: {
            verificationToken: verificationToken
        }
    });
}

export const update = (subscriptionId: string, settings: {
    schedule: {
        volume: number,
        totalDays: number,
        startDate: {
            month: number,
            day: number,
            year: number
        }
    }
}) => {
    return axios({
        url: process.env.REACT_APP_API_SUBSCRIPTIONS + '/subscriptions/' + subscriptionId,
        method: 'PUT',
        data: {
            schedule: settings.schedule
        }
    });
}
