import React, { useCallback, useEffect, useState } from 'react';
import './ScheduleSelector.scss';
import DatePicker from "react-datepicker";
import { Schedule, VolumeName } from "../../models/Models";
import { DateTime } from 'luxon';

import "react-datepicker/dist/react-datepicker.css";

interface IScheduleSelectorProps {
  onScheduleChanged: (schedule: Schedule) => void;
  schedule: Schedule;
}

const ScheduleSelector: React.FC<IScheduleSelectorProps> = ({ schedule, onScheduleChanged }) => {
  const [volume, setVolumeState] = useState(schedule.volume || 'book-of-mormon');
  const [startDate, setStartDateState] = useState(schedule.startDate.toJSDate());
  const [endDate, setEndDateState] = useState(schedule.endDate.toJSDate());

  const callback = useCallback(onScheduleChanged, [onScheduleChanged]);

  const changeSchedule = useCallback(() => {
    callback({
      volume,
      startDate: DateTime.fromJSDate(startDate),
      endDate: DateTime.fromJSDate(endDate)
    })
  }, [volume, startDate, endDate, callback]);

  useEffect(() => {
    changeSchedule();
  }, [volume, startDate, endDate, changeSchedule])

  const setVolume = (volume: VolumeName) => {
    setVolumeState(volume);
    changeSchedule();
  }

  const setStartDate = (startDate: Date) => {
    setStartDateState(startDate);
    changeSchedule();
  }

  const setEndDate = (endDate: Date) => {
    setEndDateState(endDate);
    changeSchedule();
  }

  return (
    <div id="settingsBox" className="ScheduleSelector">
      <div>
        <p>Which volume of scripture would you like to read?</p>
        <ul className="volume-list list-unstyled">
          <li>
            <label>
              <input type="radio"
                value="old-testament"
                checked={volume === "old-testament"}
                onChange={() => setVolume("old-testament")}
                 />
              <span>The Holy Bible - Old Testament</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="new-testament"
                checked={volume === "new-testament"}
                onChange={() => setVolume("new-testament")} />
              <span>The Holy Bible - New Testament</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="book-of-mormon"
                checked={volume === "book-of-mormon"}
                onChange={() => setVolume("book-of-mormon")} />
              <span>The Book of Mormon</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="doctrine-and-covenants"
                checked={volume === "doctrine-and-covenants"}
                onChange={() => setVolume("doctrine-and-covenants")} />
              <span>The Doctrine &amp; Covenants</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio"
                value="pearl-of-great-price"
                checked={volume === "pearl-of-great-price"}
                onChange={() => setVolume("pearl-of-great-price")} />
              <span>The Pearl of Great Price</span>
            </label>

          </li>
        </ul>
      </div>
      <div className="calendars row">
        <div className="col-xs-12 col-lg-6">
          <p>When would you like to start?</p>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date as Date)}
            dateFormatCalendar={"MMM yyyy"}
            startDate={startDate}
            todayButton="Today"
            selectsStart
            inline
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <p>When would you like to end?</p>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date as Date)}
            dateFormatCalendar={"MMM yyyy"}
            endDate={endDate}
            minDate={startDate}
            todayButton="Today"
            selectsEnd
            inline
          />
        </div>
      </div>
    </div>
  );
}

export default ScheduleSelector;
