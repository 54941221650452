import { DateTime } from "luxon";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Schedule } from "../models/Models";
import { getDefaultSchedule, getScheduleFromLocalStorage, getScheduleFromParams, storeScheduleToLocalStorage } from '../services/scheduleService';

export type LoadedScheduleSource = 'local' | 'default' | 'url';
export type LoadedSchedule = {
    source: LoadedScheduleSource,
    schedule: Schedule
}

const scheduleLocalStorageKey = "schedule";

export const useSchedule = (today?: DateTime): [LoadedSchedule, (schedule: Schedule) => void] => {
    let source: LoadedScheduleSource = 'default';
    const params = useParams<{ volume: string, days: string, startDate: string }>();
    const storedSchedule = getScheduleFromLocalStorage(scheduleLocalStorageKey);
    if (storedSchedule) source = 'local';
    const paramSchedule = getScheduleFromParams(params);
    if (paramSchedule) source = 'url';
    let initialSchedule: Schedule | null;
    switch (source) {
        case 'local':
            initialSchedule = storedSchedule;
            break;
        case 'url':
            initialSchedule = paramSchedule;
            break;
        default:
            initialSchedule = getDefaultSchedule(today || DateTime.local());
            break;
    }
    const [schedule, setSchedule] = useState<Schedule>(initialSchedule!);
    
    const setAndSaveSchedule = (sch: Schedule) => {
        setSchedule(sch);
        storeScheduleToLocalStorage(scheduleLocalStorageKey, sch);
    }

    const s: LoadedSchedule = { source, schedule };

    return [s, setAndSaveSchedule];
}